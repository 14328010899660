import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatBuildType } from '../../helpers/resourceItem';

class BuildListsIndexContainer extends Component {
  createRows = list => (
    list.map((buildList) => {
      const {
        id,
        buildType,
        contentId,
        inStockCount,
        name,
        plannedCount,
        untestedCount,
      } = buildList;

      const isBuildOnOrder = buildType === 'build_on_order';
      const displayPlannedCount = isBuildOnOrder ? '-' : plannedCount;
      const displayUntestedCount = isBuildOnOrder ? '-' : untestedCount;
      const displayInStockCount = isBuildOnOrder ? '-' : inStockCount;

      return (
        <tr key={contentId}>
          <td>{name}</td>
          <td>{contentId}</td>
          <td>{formatBuildType(buildType)}</td>
          <td>{displayPlannedCount}</td>
          <td>{displayInStockCount}</td>
          <td>{displayUntestedCount}</td>
          <td><Link to={`/inventory/bundles/${id}`}>Show</Link></td>
        </tr>
      );
    })
  );

  buildBadgeList = (filter, defaultFilter) => {
    const { buildType, search } = filter;
    const { buildType: defaultBuildType, search: defaultSearch } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('buildType'):
          if (buildType && buildType !== defaultBuildType) {
            badgeList.push(
              {
                order: 2,
                title: 'Built Type',
                desc: filter[key].replace(/_/g, ' '),
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  }

  render() {
    const tableProps = {
      pageTitle: 'Bundles',
      searchPlaceholder: 'Search by Name or SKU...',
      heads: [
        { key: 'name', name: 'Name' },
        { key: 'id', name: 'SKU' },
        { key: 'buildType', name: 'Build Type' },
        { key: 'planned', name: 'Planned' },
        { key: 'inStock', name: 'In Stock' },
        { key: 'untested', name: 'Untested' },
        { key: 'info', name: 'Info' },
      ],
      filterForm: [
        {
          type: 'RadioButtonGroup',
          groupLabel: 'Bundle Type',
          filterKey: 'buildType',
          options: [
            { value: '', label: 'All' },
            { value: 'prebuilt', label: 'Pre-Built' },
            { value: 'build_on_order', label: 'Build On Order' },
            { value: 'network', label: 'Network' },
          ],
        },
      ],
      createRows: this.createRows,
      buildBadgeList: this.buildBadgeList,
    };

    return (
      <IndexTableContainer {...tableProps} />
    );
  }
}

export default BuildListsIndexContainer;
