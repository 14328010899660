import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { sortNumericallyBy } from '../../../../helpers/resourceItem';

const LineItems = ({ resourceitem: { shipmentRequests } }) => {
  const sorted = sortNumericallyBy(shipmentRequests, 'id');
  const displayLineItems = sorted.map((li) => {
    const {
      id, contentId, name, quantity,
    } = li;

    const assLength = li.assignments.length;
    const trStyle = { border: assLength ? 'none' : '' };

    return (
      <Fragment key={`lineitem${id}`}>
        <tr style={trStyle}>
          <td>{contentId}</td>
          <td>{name}</td>
          <td>{quantity}</td>
        </tr>
        {
          li.assignments.length
            ? (
              <tr key={`lineitem${id}-serials`}>
                <td colSpan={3} style={{ marginLeft: 30 }}>
                  <span style={{ marginRight: 4 }}>Serial Numbers:</span>
                  <span>
                    { li.assignments.map(a => a.contentIdentifiableId).join(', ') }
                  </span>
                </td>
              </tr>
            )
            : null
        }
      </Fragment>
    );
  });
  return (
    <Row>
      <Col xs={12}>
        <h3 className="page-title mb-0">
          Line Items
        </h3>
      </Col>
      <Col md={12}>
        <Card className="pb-0">
          <CardBody className="pt-3 pb-0">
            <div className="project-summary">
              <div className="table-responsive">
                <table className="table--bordered table">
                  <thead>
                    <tr>
                      <th>SKU</th>
                      <th>Item</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayLineItems}
                  </tbody>
                </table>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

LineItems.propTypes = {
  resourceitem: PropTypes.shape({
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        itemId: PropTypes.number,
        name: PropTypes.string,
        quantity: PropTypes.number,
      }),
    ),
  }),
};

LineItems.defaultProps = {
  resourceitem: {},
};

export default LineItems;
