import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatType, formatNumber } from '../../helpers/resourceItem';

class ItemsIndexContainer extends Component {
  createRows = (list) => {
    const hasSerial = list.map(({ itemType }) => itemType).includes('serial_number');
    return (
      list.map((item) => {
        const {
          id,
          aStock,
          aStockAssigned,
          bStock,
          bStockAssigned,
          baseContentId,
          defectiveStock,
          itemType,
          name,
        } = item;

        return (
          <tr key={baseContentId}>
            <td>{name}</td>
            <td>{baseContentId}</td>
            <td>{formatType(itemType)}</td>
            <td>{formatNumber(aStock)}</td>
            {
              hasSerial
                ? (
                  <>
                    <td>{formatNumber(aStockAssigned)}</td>
                    <td>{itemType !== 'serial_number' ? '-' : formatNumber(bStock)}</td>
                    <td>{itemType !== 'serial_number' ? '-' : formatNumber(bStockAssigned)}</td>
                    <td>{itemType !== 'serial_number' ? '-' : formatNumber(defectiveStock)}</td>
                  </>
                )
                : null
            }
            <td><Link to={`/inventory/items/${id}`}>Show</Link></td>
          </tr>
        );
      })
    );
  };

  buildBadgeList = (filter, defaultFilter) => {
    const { itemType, search } = filter;
    const { defaultItemType, defaultSearch } = defaultFilter;
    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('itemType'):
          if (itemType && itemType !== defaultItemType) {
            badgeList.push(
              {
                order: 2,
                title: 'Item Type',
                desc: filter[key].replace('_', ' '),
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });
    return badgeList;
  }

  buildHeaders = (list) => {
    const hasSerial = list.map(({ itemType }) => itemType).includes('serial_number');
    const base = [
      { key: 'name', name: 'Name' },
      { key: 'id', name: 'SKU' },
      { key: 'itemType', name: 'Item Type' },
      { key: 'a_stock', name: 'A-Stock' },
    ];
    const serial = hasSerial ? [
      { key: 'a_stock_assigned', name: 'A-Assigned' },
      { key: 'b_stock', name: 'B-Stock' },
      { key: 'b_stock_assigned', name: 'B-Assigned' },
      { key: 'defective_stock', name: 'Defective' },
    ] : [];
    const info = [{ key: 'information', name: 'Info' }];
    return [...base, ...serial, ...info];
  }

  render() {
    const tableProps = {
      pageTitle: 'Inventory Items',
      searchPlaceholder: 'Search by Item Name or SKU...',
      buildHeaders: this.buildHeaders,
      filterForm: [
        {
          type: 'RadioButtonGroup',
          groupLabel: 'Item Type',
          filterKey: 'itemType',
          options: [
            { value: '', label: 'All' },
            { value: 'expensed', label: 'Expensed' },
            { value: 'serial_number', label: 'Serial Number' },
            { value: 'mailing_container', label: 'Mailing Container' },
          ],
        },
      ],
      createRows: this.createRows,
      buildBadgeList: this.buildBadgeList,
    };

    return (
      <IndexTableContainer {...tableProps} />
    );
  }
}

export default ItemsIndexContainer;
