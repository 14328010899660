import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatOrderStatus } from '../../helpers/resourceItem';

class SalesShipmentsIndexContainer extends Component {
  formatContents = shipment => (
    shipment.shipmentRequests.map((content) => {
      const { quantity, name, id } = content;
      return (
        <div key={`shipmentRequests${id}`} className="ml-4">
          {`Qty ${quantity}: ${name}`}
        </div>
      );
    })
  )

  createRows = list => (
    list.map((customerShipment) => {
      const {
        id,
        name,
        status,
      } = customerShipment;

      return (
        <tr key={id}>
          <td>{id}</td>
          <td>{formatOrderStatus(status)}</td>
          <td>{name}</td>
          <td>{this.formatContents(customerShipment)}</td>
          <td><Link to={`/orders/return-shipments/${id}`}>Show</Link></td>
        </tr>
      );
    })
  );

  buildBadgeList = (filter, defaultFilter) => {
    const {
      contentId,
      processedDate,
      processedAfterDate,
      processedBeforeDate,
      search,
      status,
    } = filter;
    const {
      contentId: defaultContentId,
      processedDate: defaultProcessedDate,
      processedAfterDate: defaultProcessedAfterDate,
      processedBeforeDate: defaultProcessedBeforeDate,
      search: defaultSearch,
      status: defaultStatus,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('contentId'):
          if (contentId && contentId !== defaultContentId) {
            badgeList.push(
              {
                order: 3,
                title: 'Contents',
                descFilterable: 'shipmentProfiles',
                filterKey: key,
              },
            );
          }
          break;
        case ('processedDate'):
          if (processedDate && processedDate !== defaultProcessedDate) {
            badgeList.push(
              {
                order: 4,
                title: 'Fulfillment Date',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('processedAfterDate'):
          if (processedAfterDate && processedAfterDate !== defaultProcessedAfterDate) {
            if (!processedBeforeDate) {
              badgeList.push(
                {
                  order: 4,
                  title: 'Fulfilled After',
                  desc: filter.processedAfterDate,
                  filterKey: key,
                },
              );
            }
          }
          break;
        case ('processedBeforeDate'):
          if (processedBeforeDate && processedBeforeDate !== defaultProcessedBeforeDate) {
            if (processedAfterDate && processedAfterDate !== defaultProcessedAfterDate) {
              badgeList.push(
                {
                  order: 4,
                  title: 'Fulfilled Between',
                  desc: `${filter.processedAfterDate} and ${filter.processedBeforeDate}`,
                  filterKey: ['processedBeforeDate', 'processedAfterDate'],
                },
              );
            } else {
              badgeList.push(
                {
                  order: 4,
                  title: 'Fulfilled Before',
                  desc: filter[key],
                  filterKey: 'processedBeforeDate',
                },
              );
            }
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('status'):
          if (status && status !== defaultStatus) {
            badgeList.push(
              {
                order: 2,
                title: 'Status',
                desc: filter[key].replace(/_/g, ' '),
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  }

  render() {
    const tableProps = {
      newButtonText: 'New Return Order',
      pageTitle: 'Return Shipments',
      searchPlaceholder: 'Search by ID or Address...',
      heads: [
        { key: 'id', name: 'Shipment ID' },
        { key: 'status', name: 'Status' },
        { key: 'recipient', name: 'Recipient' },
        { key: 'contents', name: 'Contents' },
        { key: 'information', name: 'Info' },
      ],
      filterForm: [
        {
          type: 'RadioButtonGroup',
          groupLabel: 'Shipment Status',
          filterKey: 'status',
          options: [
            { value: '', label: 'All' },
            { value: 'unprocessed', label: 'Not Processed' },
            { value: 'processed_incomplete', label: 'Processed Incomplete' },
            { value: 'processed', label: 'Processed' },
            { value: 'canceled', label: 'Canceled' },
          ],
        },
        {
          type: 'Select',
          groupLabel: 'Contents',
          filterKey: 'contentId',
          placeholder: 'Filter by Contents...',
          optionsKey: 'shipmentProfiles',
        },
      ],
      createRows: this.createRows,
      buildBadgeList: this.buildBadgeList,
    };

    return (
      <IndexTableContainer {...tableProps} />
    );
  }
}

export default SalesShipmentsIndexContainer;
