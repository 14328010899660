import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';

const ItemInformation = ({
  resourceitem: {
    name,
    id,
    pid,
    itemType,
    baseContentId,
    insuredPrice,
    aStock,
    aStockAssigned,
    bStock,
    bStockAssigned,
    defectiveStock,
  },
}) => (
  <Row>
    <Col xs={12}>
      <h3 className="page-title mb-0">
        Inventory Item Detail Page
      </h3>
    </Col>
    <Col md={12}>
      <Card className="pb-0">
        <CardBody className="pt-3 pb-0">
          <div className="project-summary">
            <div className="card__title mb-3">
              <h5 className="bold-text">{name}</h5>
            </div>
            <Row>
              <Col lg={6}>
                <table className="project-summary__info ml-2 mb-0">
                  <tbody>
                    <tr>
                      <th>Database ID:</th>
                      <td>{ id }</td>
                    </tr>
                    <tr>
                      <th>Scannable PID:</th>
                      <td>{ pid }</td>
                    </tr>
                    <tr>
                      <th>Item Type:</th>
                      <td>{ itemType }</td>
                    </tr>
                    <tr>
                      <th>SKU:</th>
                      <td>{ baseContentId }</td>
                    </tr>
                    <tr>
                      <th>Insured Price:</th>
                      <td>{ insuredPrice }</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
              <Col lg={6}>
                <table className="project-summary__info ml-2">
                  <tbody>
                    <tr>
                      <th>A-Stock:</th>
                      <td>{ aStock }</td>
                    </tr>
                    <tr>
                      <th>A-Stock Assigned:</th>
                      <td>{ aStockAssigned }</td>
                    </tr>
                    <tr>
                      <th>B-Stock:</th>
                      <td>{ bStock }</td>
                    </tr>
                    <tr>
                      <th>B-Stock Assigned:</th>
                      <td>{ bStockAssigned }</td>
                    </tr>
                    <tr>
                      <th>Defective Stock:</th>
                      <td>{ defectiveStock }</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

ItemInformation.propTypes = {
  resourceitem: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    pid: PropTypes.string,
    itemType: PropTypes.string,
    baseContentId: PropTypes.string,
    insuredPrice: PropTypes.number,
    aStock: PropTypes.number,
    aStockAssigned: PropTypes.number,
    bStock: PropTypes.number,
    bStockAssigned: PropTypes.number,
    defectiveStock: PropTypes.number,
  }),
};

ItemInformation.defaultProps = {
  resourceitem: {},
};

export default ItemInformation;
