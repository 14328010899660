import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';

import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { loginAction } from '../../../redux/actions/authActions';

import { getAuthenticated, getErrors } from '../../../redux/reducers/authReducer';

class LogInForm extends PureComponent {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps= {
    errors: [],
  }

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  componentDidMount() {
    const { authenticated, history } = this.props;
    if (authenticated) history.push('/inventory/items');
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { showPassword } = this.state;
    const { errors: errorMessages } = this.props;

    return (
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            const { dispatch, history } = this.props;
            dispatch(loginAction(values, history));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <div className="form__form-group">
              <span className="form__form-group-label">Email</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon input-icon-left">
                  <AccountOutlineIcon />
                </div>
                <Field type="email" name="email" className="login__input" />
              </div>
              <ErrorMessage name="email" component="div" className="login__error" />
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Password</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon input-icon-left">
                  <KeyVariantIcon />
                </div>
                <Field
                  type={`${showPassword ? 'text' : 'password'}`}
                  name="password"
                  className="login__input icon-right"
                />
                <button
                  className={`form__form-group-button login__input${showPassword ? ' active' : ''}`}
                  onClick={e => this.showPassword(e)}
                  type="button"
                ><EyeIcon />
                </button>
                <ErrorMessage name="password" component="div" />
              </div>
            </div>
            <div className="login__error">
              { errorMessages.map(e => <div key={e}>{e}</div>) }
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-secondary account__btn account__btn--small account__login__btn"
            >
              Sign In
            </button>
          </Form>
        )}
      </Formik>
    );
  }
}

// // start of code change
// const mapStateToProps = state => ({error: state.auth.error });
//
// export default withRouter(connect(mapStateToProps)(LogInForm));
//

const mapStateToProps = state => ({
  authenticated: getAuthenticated(state),
  errors: getErrors(state),
});

export default withRouter(connect(mapStateToProps)(LogInForm));
