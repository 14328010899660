/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { formatShipmentStatus } from '../../../../helpers/resourceItem';

const Information = (props) => {
  console.log(props);
  const {
    resourceitem: {
      id,
      orderId,
      orderTime,
      status,
      carrierServiceDescription,
      trackingNumber,
      name,
    },
  } = props;
  return (
  <Row>
    <Col xs={12}>
      <h3 className="page-title mb-0">
        Return Shipment Detail Page
      </h3>
    </Col>
    <Col md={12}>
      <Card className="pb-0">
        <CardBody className="pt-3 pb-0">
          <div className="project-summary">
            <Row>
              <Col lg={6}>
                <table className="project-summary__info ml-2 mb-0">
                  <tbody>
                    <tr>
                      <th>Database ID:</th>
                      <td>{ id }</td>
                    </tr>
                    <tr>
                      <th>Returned By:</th>
                      <td>{ name }</td>
                    </tr>
                    <tr>
                      <th>Order ID:</th>
                      <td>{ orderId }</td>
                    </tr>
                    <tr>
                      <th>Status:</th>
                      <td>{ formatShipmentStatus(status) }</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
              <Col lg={6}>
                <table className="project-summary__info ml-2">
                  <tbody>
                    <tr>
                      <th>Carrier Service:</th>
                      <td>{ carrierServiceDescription }</td>
                    </tr>
                    <tr>
                      <th>Tracking Number:</th>
                      <td>{ trackingNumber || 'n/a' }</td>
                    </tr>
                    <tr>
                      <th>Date Created:</th>
                      <td>{ orderTime }</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  </Row>
)};

Information.propTypes = {
  resourceitem: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
  }),
};

Information.defaultProps = {
  resourceitem: {},
};

export default Information;
